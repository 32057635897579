// Import packages
import React from "react";

// Import components
import Section from "./Section";

// Define component
function Footer() {
  return (
    <footer className="footer">
      <Section align="center">
        <div className="footer__logo">
          <svg
            viewBox="0 0 1250 383"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M58.781,296.111C38.521,296.111 23.659,291.179 14.195,281.316C4.732,271.452 0,257.19 0,238.529L0,0.206L59.181,0.206L59.181,250.126L85.573,250.126L85.573,296.111L58.781,296.111Z" />
            <path d="M206.334,300.909C190.872,300.909 177.077,298.377 164.947,293.312C152.818,288.247 142.554,280.916 134.157,271.319C125.76,261.722 119.295,250.126 114.763,236.53C110.231,222.934 107.965,207.739 107.965,190.944C107.965,174.15 110.231,158.955 114.763,145.359C119.295,131.763 125.76,120.234 134.157,110.77C142.554,101.307 152.818,94.042 164.947,88.977C177.077,83.912 190.872,81.38 206.334,81.38C221.796,81.38 235.591,83.912 247.721,88.977C259.85,94.042 270.114,101.307 278.511,110.77C286.908,120.234 293.373,131.763 297.905,145.359C302.437,158.955 304.702,174.15 304.702,190.944C304.702,207.739 302.437,222.934 297.905,236.53C293.373,250.126 286.908,261.722 278.511,271.319C270.114,280.916 259.85,288.247 247.721,293.312C235.591,298.377 221.796,300.909 206.334,300.909ZM206.334,254.124C218.064,254.124 227.127,250.525 233.525,243.328C239.923,236.13 243.122,225.867 243.122,212.538L243.122,169.751C243.122,156.422 239.923,146.159 233.525,138.961C227.127,131.763 218.064,128.165 206.334,128.165C194.604,128.165 185.541,131.763 179.143,138.961C172.745,146.159 169.546,156.422 169.546,169.751L169.546,212.538C169.546,225.867 172.745,236.13 179.143,243.328C185.541,250.525 194.604,254.124 206.334,254.124Z" />
            <path d="M346.689,296.111L346.689,86.178L405.87,86.178L405.87,131.763L407.869,131.763C408.936,125.899 410.802,120.234 413.468,114.769C416.134,109.304 419.666,104.439 424.064,100.174C428.463,95.908 433.728,92.509 439.859,89.977C445.991,87.444 453.188,86.178 461.452,86.178L471.849,86.178L471.849,141.36L457.054,141.36C439.726,141.36 426.863,143.626 418.466,148.158C410.069,152.69 405.87,161.087 405.87,173.35L405.87,296.111L346.689,296.111Z" />
            <path d="M593.81,300.909C577.815,300.909 563.553,298.31 551.024,293.112C538.494,287.913 527.898,280.582 519.234,271.119C510.57,261.655 504.039,250.126 499.64,236.53C495.242,222.934 493.042,207.739 493.042,190.944C493.042,174.416 495.175,159.421 499.44,145.959C503.705,132.497 510.037,120.967 518.434,111.37C526.831,101.773 537.161,94.375 549.424,89.177C561.687,83.979 575.682,81.38 591.411,81.38C608.739,81.38 623.6,84.312 635.996,90.177C648.393,96.042 658.523,103.906 666.387,113.769C674.251,123.633 679.982,135.029 683.581,147.958C687.18,160.887 688.98,174.416 688.98,188.545L688.98,206.14L554.223,206.14L554.223,209.339C554.223,223.201 557.955,234.197 565.419,242.328C572.883,250.459 584.613,254.524 600.608,254.524C612.871,254.524 622.867,252.058 630.598,247.127C638.329,242.195 645.527,236.263 652.191,229.332L681.782,266.12C672.451,277.05 660.255,285.581 645.194,291.712C630.132,297.844 613.004,300.909 593.81,300.909ZM592.61,124.966C580.614,124.966 571.217,128.898 564.419,136.762C557.622,144.626 554.223,155.089 554.223,168.152L554.223,171.351L627.799,171.351L627.799,167.752C627.799,154.956 624.933,144.626 619.202,136.762C613.47,128.898 604.607,124.966 592.61,124.966Z" />
            <path d="M730.966,296.111L730.966,86.178L790.147,86.178L790.147,121.767L792.546,121.767C796.279,110.837 802.81,101.373 812.14,93.376C821.47,85.378 834.4,81.38 850.928,81.38C872.521,81.38 888.849,88.577 899.912,102.973C910.975,117.368 916.507,137.895 916.507,164.553L916.507,296.111L857.326,296.111L857.326,169.351C857.326,155.756 855.193,145.692 850.928,139.161C846.662,132.63 839.065,129.364 828.135,129.364C823.337,129.364 818.671,129.964 814.139,131.164C809.608,132.363 805.542,134.296 801.943,136.962C798.345,139.628 795.479,142.893 793.346,146.759C791.213,150.624 790.147,155.089 790.147,160.154L790.147,296.111L730.966,296.111Z" />
            <path d="M957.294,296.111L957.294,249.326L1061.66,132.163L960.893,132.163L960.893,86.178L1130.44,86.178L1130.44,132.563L1025.67,250.126L1133.24,250.126L1133.24,296.111L957.294,296.111Z" />
            <path d="M1214.81,300.509C1202.82,300.509 1193.95,297.51 1188.22,291.512C1182.49,285.514 1179.62,277.85 1179.62,268.52L1179.62,260.522C1179.62,251.192 1182.49,243.528 1188.22,237.53C1193.95,231.532 1202.82,228.532 1214.81,228.532C1226.81,228.532 1235.67,231.532 1241.4,237.53C1247.13,243.528 1250,251.192 1250,260.522L1250,268.52C1250,277.85 1247.13,285.514 1241.4,291.512C1235.67,297.51 1226.81,300.509 1214.81,300.509Z" />
          </svg>
          <address className="footer__address">
            Lorenz AS <span className="color--lime">[</span>NO996106772
            <span className="color--lime">]</span>
            <br />
            Markveien 2<br />
            NO-3117 Tønsberg, Norway
          </address>
          <nav className="footer__menu">
            <ul className="footer__list">
              <li className="footer__item">
                <a className="footer__link" href="/brand">
                  Brand
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="/privacy">
                  Privacy
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </Section>
    </footer>
  );
}

// Export component
export default Footer;
